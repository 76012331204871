const Footer = () => {
  const styles = {
    footer: {
      width: "100%",
      backgroundColor: "#282c34", // Dark background for a sleek look
      color: "#ffffff",
      textAlign: "center",
      padding: "10px 0",
      position: "fixed",
      bottom: 0, // Keeps footer at the bottom
      left: 0,
      zIndex: 100,
      fontSize: "14px",
      fontWeight: "500",
      boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.2)",
    },
    poweredBy: {
      color: "#888", // A nice color to stand out for the company name
      fontWeight: "thin",
      textDecoration: "none",
      marginLeft: "5px",
      fontSize: 12,
    },
    icon: {
      marginRight: "5px",
      verticalAlign: "middle", // Aligns icon with the text
      width: "18px",
      height: "18px",
    },
  };

  return (
    <footer style={styles.footer}>
      <span style={{ color: "#ccc", fontSize: 8 }}>Powered by</span>
      <a
        href="https://canaratechlabs.com"
        style={styles.poweredBy}
        target="new"
      >
        <img
          src="https://canaratechlabs.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.dc75053b.png&w=256&q=75"
          alt="CanaraTechLabs Icon"
          style={styles.icon}
        />
        CanaraTechLabs
      </a>
    </footer>
  );
};

export default Footer;
