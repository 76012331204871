const FoodCategories = ({
  selectedCategory,
  selectCategory,
  selectedType,
  setSelectedType,
  categories = [],
}) => {
  const containerStyle = {
    textAlign: "center",
    background: "white",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "15px",
    width: "90%",
    maxWidth: "400px",
    margin: "auto",
    display: "flex",
  };

  const optionStyle = {
    display: "flex",
    cursor: "pointer",
    // padding: "2px",
    margin: "5px",
    borderRadius: "8px",
    transition: "background 0.3s",
    fontSize: "12px",
    // display: "inline-block",
    alignItems: "center",
    overflow: "hidden",
  };

  const vegStyle = {
    ...optionStyle,
    border: selectedType === "veg" ? "1px solid #4caf50" : "1px solid #ccc",
  };

  const nonVegStyle = {
    ...optionStyle,
    border: selectedType === "non-veg" ? "1px solid #f44336" : "1px solid #ccc",
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row", // Set to row for horizontal layout
      overflowX: "auto", // Enable horizontal scrolling
      whiteSpace: "nowrap", // Prevent chips from wrapping to the next line
      padding: "10px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      maxWidth: "100%", // Limit the width to the container
      position: "sticky",
    },
    chip: {
      padding: "10px 15px",
      marginRight: "10px", // Add right margin for spacing between chips
      backgroundColor: "#fff",
      borderRadius: "20px",
      display: "inline-block",
      textAlign: "center",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "500",
      color: "#333",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      transition: "background-color 0.3s ease",
      whiteSpace: "nowrap", // Keep text from wrapping
    },
    chipHover: {
      backgroundColor: "#ff6f61", // Highlight color on hover
      color: "#fff",
    },
  };

  return (
    <div style={{ position: "sticky", top: 0 }}>
      <div
        style={{
          background: "#f1f1f1",
          // height: 80,
          display: "flex",
          overflow: "hidden",
          width: "100%",
          paddingTop: 10,
        }}
      >
        <div
          style={{
            background: "#f1f1f1",
            display: "flex",
            overflow: "scroll",
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              margin: 10,

              ...styles.chip,
              background: selectedCategory === "All" ? "red" : "#fff",
              color: selectedCategory === "All" ? "#fff" : "#222",
            }}
            onClick={(e) => {
              selectCategory("All");
            }}
          >
            {"All"}
          </div>
        </div>
        <div
          style={{
            background: "#f1f1f1",

            display: "flex",
            overflow: "scroll",

            flex: 1,
            paddingBottom: 10,
          }}
        >
          {categories.map((category, index) => (
            <div
              style={{
                margin: 10,
                ...styles.chip,
                background: selectedCategory === category ? "red" : "#fff",
                color: selectedCategory === category ? "#fff" : "#222",
              }}
              key={index}
              onClick={(e) => {
                selectCategory(category);
              }}
            >
              {category}
            </div>
          ))}
        </div>
      </div>
      <div style={containerStyle}>
        <div style={vegStyle} onClick={() => setSelectedType("veg")}>
          <p
            style={{
              margin: 0,
              padding: 10,
              backgroundColor: selectedType === "veg" ? "#4caf50" : "#fff",

              color: selectedType === "veg" ? "white" : "black",
            }}
          >
            Vegetarian
          </p>
          <img
            // src={require("../../assets/foodImages/veg.png")}
            src={require("../../assets/foodImages/veg.png")}
            style={{ margin: 5 }}
            height={20}
          />
        </div>
        <div style={nonVegStyle} onClick={() => setSelectedType("non-veg")}>
          <p
            style={{
              margin: 0,
              padding: 10,
              backgroundColor: selectedType === "non-veg" ? "#f44336" : "#fff",

              color: selectedType === "non-veg" ? "white" : "black",
            }}
          >
            Non-Vegetarian
          </p>
          <img
            src={require("../../assets/foodImages/nonveg.png")}
            style={{ margin: 5 }}
            height={20}
          />
        </div>
        <div
          onClick={() => setSelectedType("")}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              padding: 10,
              color: "#555",
            }}
          >
            clear
          </p>
        </div>
      </div>
    </div>
  );
};

export default FoodCategories;
