// src/components/HotelBanner.js

import React from "react";
import "./HotelBanner.css"; // Import the CSS file for styling

const HotelBanner = ({ hotel }) => {
  return (
    <div className="hotel-banner">
      <img src={hotel.image} alt={hotel.title} className="hotel-image" />
      <div className="hotel-info">
        <h2 className="hotel-title">{hotel.title}</h2>
        <p className="hotel-address">{hotel.address}</p>
      </div>
    </div>
  );
};

export default HotelBanner;
