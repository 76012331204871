// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Menue from "./pages/Menu";
// Components
const Home = () => <h2>Home Page</h2>;
const About = () => <h2>About Page</h2>;
const Contact = () => <h2>Contact Page</h2>;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/hotel/:id" element={<Contact />} />
        <Route path="/hotel/:id/menu/:menuId" element={<Menue />} />
        <Route
          path="/hotel/:id/menue/:menuId/categories"
          element={<Contact />}
        />
        <Route
          path="/hotel/:id/menu/:menuId/categories/:categoryId"
          element={<Contact />}
        />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
