// axiosInstance.js

import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL ||
    "https://api.hotelqr.canaratechlabs.com/api/", // Replace with your API base URL
  timeout: 10000, // Request timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    // You can add other default headers here
  },
});

// Request interceptor to attach tokens or modify requests
axiosInstance.interceptors.request.use(
  (config) => {
    // For example, attach an Authorization token if available
    const token = localStorage.getItem("token"); // Adjust based on your storage strategy
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response interceptor to handle responses globally
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    if (error.response) {
      // Handle specific error statuses
      switch (error.response.status) {
        case 401:
          // Unauthorized, possibly redirect to login
          // Example:
          // window.location.href = '/login';
          break;
        case 403:
          // Forbidden
          break;
        case 404:
          // Not Found
          break;
        case 500:
          // Server Error
          break;
        default:
          break;
      }
      // You can also handle error messages globally
      // Example: Display a toast notification
    } else if (error.request) {
      // The request was made but no response was received
      // Handle network errors
    } else {
      // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
